// api/notifications.js

import axios from "axios";
const API_URL =
  process.env.VUE_APP_API_URL + "/notifications" || "apiv1/notifications";
// const API_URL = "api/notifications";

export default {
  getNotifications(user) {
    return axios.get(API_URL, {
      params: {
        token: user.token,
        user_id: user.user_id
      }
    });
  },

  markNotificationReaded(payload) {
    return axios.post(API_URL + "/read", {
      token: payload.token,
      user_id: payload.user_id,
      notification_id: payload.notification_id,
      _method: "PUT"
    });
  }
};
