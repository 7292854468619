<template>
  <v-sheet class="py-4" min-height="500px">
    <v-row justify="center">
      <v-col class="px-2">
        <!-- <v-tabs v-model="tabItem" grow>
          <v-tab>
            Avís Legal
          </v-tab>
          <v-tab>
            Política de Privacitat
          </v-tab>
          <v-tab>
            Condicions d´ús
          </v-tab>
        </v-tabs> -->
        <v-tabs-items v-model="tab" class="py-2">
          <v-tab-item>
            <h3 class="header text-center my-2 primary--text">
              Política de Privacitat
            </h3>
            <div class="text-justify">
              <p>
                A AUTOCARES DEL PANADES SA ens preocupem per la privacitat i la
                transparència.
              </p>
              <p>
                A continuació, indiquem detalladament el tractament de les dades
                personals que realitzem, així com tota la informació
                relacionada.
              </p>
              <h4>Qui és el responsable del tractament de les seves dades?</h4>
              <p>
                AUTOCARES DEL PANADES SA <br />
                A43007814 <br />
                C/ Tarragonès, 34 Pol. Ind. Molí d'en Serra – 43710 Santa Oliva
                – Tarragona. <br />
                977660821 <br />
                direccio@autocarsdelpenedes.com
              </p>
              <h4>
                Quins són els seus drets quan ens facilita les seves dades?
              </h4>
              <p>
                Qualsevol persona té dret a obtenir confirmació sobre si a
                AUTOCARES DEL PANADES SA estem tractant, o no, dades personals
                que els concerneixin.
              </p>
              <p>
                Les persones interessades tenen dret a accedir a les seves dades
                personals, així com a sol·licitar la rectificació de les dades
                inexactes o, si escau, sol·licitar la seva supressió quan, entre
                altres motius, les dades ja no siguin necessàries per a les
                finalitats que van ser recollides. També té dret a la
                portabilitat de les seves dades.
              </p>
              <p>
                En determinades circumstàncies, els interessats podran
                sol·licitar la limitació del tractament de les seves dades, en
                aquest cas únicament les conservarem per a l'exercici o la
                defensa de reclamacions.
              </p>
              <p>
                En determinades circumstàncies i per motius relacionats amb la
                seva situació particular, els interessats podran oposar-se al
                tractament de les seves dades. En aquest cas, AUTOCARES DEL
                PANADES SA deixarà de tractar les dades, excepte per motius
                legítims imperiosos, o l'exercici o la defensa de possibles
                reclamacions.
              </p>
              <p>
                Podrà exercir materialment els seus drets de la següent forma:
                dirigint-se a direccio@autocarsdelpenedes.com o C/ Tarragonès,
                34 Pol. Ind. Molí d'en Serra. 43710 Santa Oliva - Tarragona.
              </p>
              <p>
                Quan s’enviïn comunicacions comercials utilitzant l'interès
                legítim del responsable com a base legal, l'interessat podrà
                oposar-se al tractament de les seves dades per aquesta
                finalitat.
              </p>
              <p>
                El consentiment atorgat és per a totes les finalitats indicades
                la base legal del qual és el consentiment de l'interessat. Vostè
                té dret a retirar aquest consentiment en qualsevol moment, sense
                afectar la licitud del tractament basat en el consentiment previ
                a la seva retirada.
              </p>
              <p>
                En el cas que senti vulnerats els seus drets pel que fa a la
                protecció de les seves dades personals, especialment quan no
                hagi obtingut satisfacció en l’exercici dels seus drets, pot
                presentar una reclamació davant l’Autoritat de Control en
                matèria de Protecció de Dades competent a través del seu lloc
                web: www.aepd.es.
              </p>
              <h4>Com hem obtingut les seves dades?</h4>
              <p>
                Les dades personals que tractem a AUTOCARES DEL PANADES SA
                provenen de: El propi interessat.
              </p>

              <h3>1. Tractament de les dades de contactes web.</h3>
              <h4>Amb quina finalitat tractem les seves dades personals?</h4>
              <p>
                A AUTOCARES DEL PANADES SA tractem la informació que ens
                faciliten les persones interessades amb la finalitat de
                realitzar la gestió de potencials clients que s'han interessat
                pels nostres productes i/o serveis, així com altres contactes
                comercials. En el cas que no faciliti les seves dades personals,
                no podrem complir amb les funcionalitats descrites anteriorment.
                <br />
                No es prendrà cap decisió automatitzada en funció de les dades
                facilitades.
              </p>
              <h4>Durant quant de temps conservarem les seves dades?</h4>
              <p>
                Les dades es conservaran mentre l'interessat no sol·liciti la
                seva supressió.
              </p>
              <h4>
                Quina és la legitimació per al tractament de les seves dades?
              </h4>
              <p>
                Indiquem la base legal per al tractament de les seves dades:
              </p>
              <ul>
                <li>Execució d'un contracte: Atendre la seva sol·licitud.</li>
                <li>
                  Consentiment de l'interessat: Enviar comunicacions comercials,
                  fins i tot per via electrònica.
                </li>
              </ul>

              <h4>A quins destinataris es comunicaran les seves dades?</h4>
              <p>
                No es cediran dades a tercers, excepte obligació legal.
              </p>

              <h4>Transferències de dades a tercers països</h4>
              <p>
                No estan previstes transferències de dades a tercers països.
              </p>

              <h3>2. Tractament de les dades del correu electrònic.</h3>
              <h4>Amb quina finalitat tractem les seves dades personals?</h4>
              <p>
                A AUTOCARES DEL PANADES SA tractem la informació que ens
                faciliten les persones interessades amb la finalitat de
                prestar-li els serveis que ens ha sol·licitat, donar resposta a
                les seves sol·licituds d'informació i enviar-li comunicacions
                comercials. En el cas que no faciliti les seves dades personals,
                no podrem complir amb les funcionalitats descrites anteriorment.
              </p>

              <h4>Durant quant de temps conservarem les seves dades?</h4>
              <p>
                Les dades es conservaran mentre l'interessat no sol·liciti la
                seva supressió.
              </p>

              <h4>
                Quina és la legitimació per al tractament de les seves dades?
              </h4>
              <p>
                Indiquem la base legal per al tractament de les seves dades:
              </p>
              <ul>
                <li>
                  Execució d'uncontracte: Prestació dels serveis sol·licitats.
                </li>
                <li>
                  Interès legítim del Responsable: Enviament de comunicacions
                  comercials sobre els nostres serveis i/o productes.
                </li>
                <li>
                  Consentiment de l'interessat: Enviament de comunicacions
                  comercials sobre els nostres serveis i/o productes segons
                  l'interès mostrat.
                </li>
              </ul>

              <h4>A quins destinataris es comunicaran les seves dades?</h4>
              <p>No es cediran dades a tercers, excepte obligació legal.</p>

              <h4>Transferències de dades a tercers països</h4>
              <p>
                No estan previstes transferències de dades a tercers països.
              </p>

              <h3>3. Tractament de les dades d’usuaris Bus Flexible.</h3>
              <h4>Amb quina finalitat tractem les seves dades personals?</h4>
              <p>
                A AUTOCARES DEL PANADES SA tractem la informació que ens
                faciliten les persones interessades amb la finalitat realitzar
                la gestió administrativa i organitzativa dels serveis
                sol·licitats. En el cas que no faciliti les seves dades
                personals, no podrem complir amb les funcionalitats descrites
                anteriorment.
              </p>
              <p>
                No es prendran decisions automatitzades a partir de les dades
                facilitades.
              </p>

              <h4>Durant quant de temps conservarem les seves dades?</h4>
              <p>
                Les dades es conservaran mentre l'interessat no sol·liciti la
                seva supressió i durant els anys necessaris per complir amb les
                obligacions legals.
              </p>

              <h4>
                Quina és la legitimació per al tractament de les seves dades?
              </h4>
              <p>
                Indiquem la base legal per al tractament de les seves dades:
              </p>
              <ul>
                <li>
                  Execució d'un contracte: Gestió administrativa i organitzativa
                  de usuaris.
                </li>
              </ul>

              <h4>A quins destinataris es comunicaran les seves dades?</h4>
              <p>No es cediran dades a tercers, excepte obligació legal.</p>

              <h4>Transferències de dades a tercers països</h4>
              <p>
                No estan previstes transferències de dades a tercers països.
              </p>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "legal-texts",

  // data: () => ({
  //   tabItem: 0
  // }),

  props: ["tab"]

  // beforeMount() {
  //   this.tabItem = this.tab;
  // },

  // beforeDestroy() {
  //   this.tabItem = this.tab;
  // }

  // methods: {}
};
</script>
