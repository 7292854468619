import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
// import { makeServer } from "./server";
import "./registerServiceWorker";
import VueDateFns from "vue-date-fns";
import locale from "date-fns/locale/es";
// ** START LeafLet (maps) Vue wrapper
// import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

// Vue.component("l-map", LMap);
// Vue.component("l-tile-layer", LTileLayer);
// Vue.component("l-marker", LMarker);
// ** END LeafLet (maps) Vue wrapper

// Global Filter for dates i EU/ES format
Vue.use(VueDateFns, "dd-MM-yyyy' 'HH:mm 'h.' ", { locale }, "dateTimeEUformat");

// Global filter for hours (string) remove las 3 chars (00:00 not 00:00:00)
Vue.filter("formatTimeString", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.substring(0, value.length - 3);
});

// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.common["Access-Control-Allow-Methods"] =
//   "GET, PUT, POST, DELETE, OPTIONS";
// axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// [
//   "https://www.pndssm.org",
//   "https://www.googleapis.com"
// ];

axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("destroyToken");
      console.log("Token expired!");
      return router.push("/login-register");
    }
  }
});

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
