// api/auth.js

import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL + "/pasbus" || "apiv1/pasbus";
// const API_URL = "api/pasbus";

export default {
  getPasbusLines(user) {
    return axios.get(API_URL + "/lines", {
      params: {
        token: user.token,
        user_id: user.user_id
      }
    });
  },
  getPasbusStops(payload) {
    return axios.get(API_URL + "/line/stops", {
      params: {
        token: payload.token,
        user_id: payload.user_id,
        line_id: payload.line_id
      }
    });
  },
  pasbusReservation(pasbusRequest) {
    return axios.post(API_URL + "/reservation", {
      token: pasbusRequest.token,
      user_id: pasbusRequest.user_id,
      source_id: pasbusRequest.source_id,
      destination_id: pasbusRequest.destination_id,
      date: pasbusRequest.date,
      arrivaltime: pasbusRequest.arrivaltime,
      passengers: pasbusRequest.passengers,
      pmr: pasbusRequest.pmr,
      reasontrip: pasbusRequest.reasontrip,
      note: pasbusRequest.note
    });
  },

  pasbusReasonTrip(user) {
    return axios.get(API_URL + "/reasonstrip", {
      params: {
        token: user.token,
        user_id: user.user_id
      }
    });
  }
};
