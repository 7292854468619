// api/auth.js

import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL + "/user" || "apiv1/user";
// const API_URL = "api/user";

export default {
  login(credentials) {
    return axios.post(API_URL + "/check", credentials);
  },

  retrieveToken(credentials) {
    return axios.post(API_URL + "/check", credentials);
  },

  register(user) {
    return axios.post(API_URL + "/create", user);
  },

  forgotPassword(payload) {
    return axios.get(API_URL + "/recover", {
      params: {
        email: payload.email,
        phone: payload.phone
      }
    });
  },

  resetPassword(payload) {
    return axios.post(API_URL + "/recover", {
      remember_token: payload.remember_token,
      password: payload.password,
      repassword: payload.repassword
    });
  }
};
