// src/api/seeker.js
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL + "/seeker" || "apiv1/seeker";
// const API_URL = "api/seeker";

export default {
  getCities() {
    return axios.get(API_URL + "/cities");
  },
  getStops(cityId) {
    return axios.get(API_URL + "/city/stops", {
      params: {
        city_id: cityId
      }
    });
  },
  getDestinationCities(stopId) {
    return axios.get(API_URL + "/tocities", {
      params: {
        stop_id: stopId
      }
    });
  },
  getSchedules(origenId, destiId, date) {
    return axios.get(API_URL + "/schedule", {
      params: {
        source_id: origenId,
        destination_id: destiId,
        date: date
      }
    });
  }
};
