<template>
  <v-container class="fill-height text-center">
    <v-row class="text-center">
      <v-col cols="12" class="mb-2" align-self="start">
        <h2 class="header font-weight-bold my-2 white--text">
          RESSETEJA EL TEU PASSWORD
        </h2>

        <v-spacer></v-spacer>

        <p class="subheading font-weight-regular mb-5 white--text">
          A continuació escriu el teu nou password i confirma'l.
        </p>
      </v-col>
    </v-row>

    <v-alert type="error" v-if="showError" class="my-2 mx-auto">
      Oops! No ha estat possible canviar el password.
    </v-alert>
    <v-alert type="success" v-if="showSuccess" class="my-2 mx-auto">
      El teu nou password s'ha actualitzat correctament!
    </v-alert>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow"
    ></v-progress-linear>

    <v-row justify="center" class="text-center">
      <v-col sm="12" md="8">
        <v-card grow class="px-md-4">
          <v-card-title class="title-1 grey--text text-center">
            <span class="mx-auto">Canvi Contrassenya</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="resetPasswordForm"
              @submit.prevent="submitResetPassword"
              v-model="validResetForm"
            >
              <v-row justify="center">
                <v-col cols="12">
                  <div class="text-center">
                    <div v-if="userInfo">
                      <h4>
                        Hola,
                        <strong>
                          {{ userInfo.name }} {{ userInfo.surnames }}
                        </strong>
                      </h4>
                    </div>
                    Si us plau escriu el teu nou password i repeteix-lo per a
                    confirmar-lo.
                  </div>
                </v-col>
                <v-col dense cols="12" md="6">
                  <v-text-field
                    v-model.trim="resetForm.password"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show3 ? 'text' : 'password'"
                    autocomplete="new-password"
                    label="Nou Password"
                    name="input-pw-rc"
                    required
                    hint="Mínim 8 caràcters"
                    counter
                    @click:append="show3 = !show3"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    block
                    v-model.trim="resetForm.repassword"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, passwordMatchRecover]"
                    :type="show3 ? 'text' : 'password'"
                    autocomplete="password-confirmation"
                    label="Confirma Nou Password"
                    name="input-rpw-rc"
                    required
                    counter
                    @click:append="show3 = !show3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn text color="orange darken-1" @click="reset()">
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    x-large
                    block
                    :disabled="validResetForm === false"
                    color="success"
                    @click="submitResetPassword"
                  >
                    Enviar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="80"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "reset-password",

  data: () => ({
    remember_token: null,
    loading: false,
    userInfo: null,
    showError: false,
    showSuccess: false,
    validResetForm: true,
    resetForm: {
      password: "",
      repassword: ""
    },
    show3: false,
    rules: {
      required: value => !!value || "Requerit",
      min: v => (v && v.length >= 8) || "Min 8 caràcters"
    }
  }),

  created() {
    this.userInfo = this.$store.getters.getUserInfo;
    this.remember_token = this.$route.params.token;
  },

  computed: {
    passwordMatchRecover() {
      return () =>
        this.resetForm.password === this.resetForm.repassword ||
        "El Password ha de coincidir";
    }
  },

  methods: {
    ...mapActions([
      "login",
      "retrieveToken",
      "forgotPassword",
      "resetPassword"
    ]),

    validateResetForm() {
      this.$refs.resetPasswordForm.validate();
    },

    reset() {
      this.$refs.resetPasswordForm.reset();
      this.$router.push({ path: "/" }).catch(() => {});
    },

    errorAlert() {
      this.showError = true;
      var v = this;
      setTimeout(function() {
        v.showError = false;
      }, 4000);
      this.loading = false;
    },

    successAlert() {
      this.showSuccess = true;
      var v = this;
      setTimeout(function() {
        v.showSuccess = false;
        v.goLogin();
      }, 4000);
      this.loading = false;
    },

    async submitResetPassword() {
      let formData = this.resetForm;
      let userData = {
        password: formData.password,
        repassword: formData.repassword,
        remember_token: this.remember_token
      };
      this.loading = true;
      try {
        let respData = await this.resetPassword(userData);
        if (respData.data[0] === "user") {
          this.userInfo = respData.data[1];
          // console.log("Reset Password result:" + respData.statusText);
          this.showError = false;
          this.successAlert();
        } else {
          this.errorAlert();
        }
        this.loading = false;
      } catch (error) {
        this.errorAlert();
      }
    },

    goLogin() {
      this.$router.push({ path: "/login-register" }).catch(() => {});
    }
  }
};
</script>
