import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Auth from "@/api/auth";
import Messages from "@/api/messages";
import Schedules from "@/api/schedules";
import Seeker from "@/api/seeker";
import Pasbus from "@/api/pasbus";
import Cridabus from "@/api/cridabus";
import Subscriptions from "@/api/subscriptions";
import Notifications from "@/api/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("access_token") || null,
    user: null,
    messages: null,
    lines: null,
    cities: null,
    destinationCities: null,
    pasbusLines: null,
    cridabusCities: null,
    busStopsOrigen: null,
    busStopsDesti: null,
    pasbusOrigens: null,
    pasbusDestins: null,
    schedules: null,
    currentSearch: null,
    iOsInstallPopup: null,
    pasbusRequest: null,
    pasbusReasonTrip: null,
    legalTextsModal: false,
    subscriptions: [],
    subscriptionLines: null,
    notifications: [],
    userPrefs: {
      scheduleSearcher: false, // Cercador Horaris
      scheduleTimeTable: false, // false-Timeline, true-Tabletime
      pasbusForm: false, // Formulari Pasbus
      notificationsWidget: true // Llistat notificacions
    },
    bannerInDevelopment: true
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    getUserInfo(state) {
      return state.user;
    },
    getIosInstallPopup(state) {
      return state.iOsInstallPopup;
    },
    getBannerInDevelopment(state) {
      return state.bannerInDevelopment;
    },
    getMessages(state) {
      return state.messages;
    },
    getLines(state) {
      return state.lines;
    },
    getCities(state) {
      return state.cities;
    },
    getBusStopsOrigen(state) {
      return state.busStopsOrigen;
    },
    getBusStopsDesti(state) {
      return state.busStopsDesti;
    },
    getCurrentSearch(state) {
      return state.currentSearch;
    },
    getPasbusLines(state) {
      return state.pasbusLines;
    },
    getCridabusCities(state) {
      return state.cridabusCities;
    },
    getPasbusRequest(state) {
      return state.pasbusRequest;
    },
    getPasbusReasonTrip(state) {
      return state.pasbusReasonTrip;
    },
    getLegalTextsModal(state) {
      return state.legalTextsModal;
    },
    getSubscriptions(state) {
      return state.subscriptions;
    },
    getSubscriptionLines(state) {
      return state.subscriptionLines;
    },
    getNotifications(state) {
      return state.notifications;
    },
    getUserPrefs(state) {
      return state.userPrefs;
    }
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token;
    },
    destroyToken(state) {
      state.token = null;
    },
    setIosInstallPopup(state) {
      state.iOsInstallPopup = false;
    },
    hideBannerInDevelopment(state) {
      state.bannerInDevelopment = false;
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    setUser(state, user) {
      state.user = user;
    },
    setLines(state, lines) {
      state.lines = lines;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setDestinationCities(state, cities) {
      state.destinationCities = cities;
    },
    setBusStopsOrigen(state, busstops) {
      state.busStopsOrigen = busstops;
    },
    setBusStopsDesti(state, busstops) {
      state.busStopsDesti = busstops;
    },
    setSchedules(state, schedules) {
      state.schedules = schedules;
    },
    setCurrentSearch(state, currentSearch) {
      state.currentSearch = currentSearch;
    },
    setPasbusLines(state, pasbuslines) {
      state.pasbusLines = pasbuslines;
    },
    setCridabusCities(state, cridabusCities) {
      state.cridabusCities = cridabusCities;
    },
    setPasbusOrigens(state, stops) {
      state.pasbusOrigens = stops;
    },
    setPasbusDestins(state, stops) {
      state.pasbusDestins = stops;
    },
    setPasbusReasonTrip(state, reasons) {
      state.pasbusReasonTrip = reasons;
    },
    setPasbusRequest(state, pasbusRequest) {
      state.pasbusRequest = pasbusRequest;
    },
    setPasbusReceived(state, receivedConfirmation) {
      state.pasbusRequest.received = receivedConfirmation;
    },
    setLegalTextsModal(state, value) {
      state.legalTextsModal = value;
    },
    setSubscriptionLines(state, lines) {
      state.subscriptionLines = lines;
    },
    addSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    removeSubscription(state, subscriptionId) {
      const i = state.subscriptions
        .map(item => item.id)
        .indexOf(subscriptionId);
      state.subscriptions.splice(i, 1);
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setUserPrefs(state, userPrefs) {
      state.userPrefs = userPrefs;
    }
  },
  actions: {
    retrieveToken(context, credentials) {
      return new Promise((resolve, reject) => {
        Auth.retrieveToken(credentials)
          .then(response => {
            console.log("info: " + JSON.stringify(response.data));
            const user = response.data[1];
            const token = user.token;
            localStorage.setItem("access_token", token);
            context.commit("retrieveToken", token);
            context.commit("setUser", user);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            localStorage.removeItem("access_token");
            context.commit("destroyToken");
            reject(error);
          });
      });
    },
    destroyToken(context) {
      if (context.getters.loggedIn) {
        localStorage.removeItem("access_token");
        context.commit("destroyToken");
      }
    },
    login(context, credentials) {
      return new Promise((resolve, reject) => {
        Auth.login(credentials)
          .then(response => {
            let data = response.data;
            // console.log(data);
            if (data[0] == "user") {
              let user = data[1];
              let token = user.token;
              let userData = {
                userId: user.id,
                name: user.name,
                surnames: user.surnames,
                email: user.email,
                phone: user.phone
              };
              localStorage.setItem("access_token", token);
              context.commit("retrieveToken", token);
              context.commit("setUser", userData);
            } else {
              localStorage.removeItem("access_token");
              context.commit("destroyToken");
            }
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            localStorage.removeItem("access_token");
            context.commit("destroyToken");
            reject(error);
          });
      });
    },
    registerUser(context, user) {
      return new Promise((resolve, reject) => {
        Auth.register(user)
          .then(response => {
            let queryResult;
            if (response.data[0] == "user") {
              let user = response.data[1];
              let token = user.token;
              let userData = {
                userId: user.id,
                name: user.name,
                surnames: user.surnames,
                dni: user.dni,
                email: user.email,
                phone: user.phone
              };
              localStorage.setItem("access_token", token);
              context.commit("retrieveToken", token);
              context.commit("setUser", userData);
              queryResult = 1;
            } else {
              // console.log("error: " + JSON.stringify(response.data));
              localStorage.removeItem("access_token");
              context.commit("destroyToken");
              queryResult = response.data;
            }
            // console.log(queryResult);
            resolve(queryResult);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    forgotPassword(context, userData) {
      return new Promise((resolve, reject) => {
        Auth.forgotPassword(userData)
          .then(response => {
            const user = response.data[1];
            context.commit("setUser", user);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            // context.commit("setUser", null);
            reject(error);
          });
      });
    },
    resetPassword(context, user) {
      return new Promise((resolve, reject) => {
        Auth.resetPassword(user)
          .then(response => {
            const user = response.data[1];
            const token = user.token;
            // localStorage.setItem("access_token", token);
            context.commit("retrieveToken", token);
            // context.commit("setUser", user);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            // context.commit("setUser", null);
            reject(error);
          });
      });
    },
    setIosInstallPopup(context) {
      context.commit("setIosInstallPopup");
    },
    setMessages(context) {
      return new Promise((resolve, reject) => {
        Messages.getMessages()
          .then(response => {
            // console.log(response.data);
            const messages = response.data;
            context.commit("setMessages", messages);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setLines(context) {
      return new Promise((resolve, reject) => {
        Schedules.getLines()
          .then(response => {
            // console.log(response.data);
            const lines = response.data;
            context.commit("setLines", lines);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setCities(context) {
      return new Promise((resolve, reject) => {
        Seeker.getCities()
          .then(response => {
            // console.log(response.data);
            const cities = response.data;
            context.commit("setCities", cities);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setDestinationCities(context, stopId) {
      return new Promise((resolve, reject) => {
        Seeker.getDestinationCities(stopId)
          .then(response => {
            // console.log(response.data);
            const cities = response.data;
            context.commit("setDestinationCities", cities);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setStops(context, cityId) {
      return new Promise((resolve, reject) => {
        Seeker.getStops(cityId)
          .then(response => {
            // console.log(response.data.origen);
            const busstops = response.data;
            context.commit("setBusStopsOrigen", busstops);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setBusStopsDesti(context, cityId) {
      return new Promise((resolve, reject) => {
        Seeker.getStops(cityId)
          .then(response => {
            // console.log(response.data.desti);
            const busstops = response.data;
            context.commit("setBusStopsDesti", busstops);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setSchedules(context, searchData) {
      return new Promise((resolve, reject) => {
        Seeker.getSchedules(
          searchData.paradaOrigenId,
          searchData.paradaDestiId,
          searchData.date
        )
          .then(response => {
            // console.log("Horaris" + response.data);
            const schedules = response.data;
            context.commit("setSchedules", schedules);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setCurrentSearch(context, currentSearch) {
      let searchData = {
        cityOrigen: currentSearch.selectedCityOrigen,
        cityDesti: currentSearch.selectedCityOrigen,
        paradaOrigen: currentSearch.selectedParadaOrigen,
        paradaDesti: currentSearch.selectedParadaDesti,
        data: currentSearch.selectedDate
      };
      context.commit("setCurrentSearch", searchData);
      return;
    },
    setPasbusLines(context, user) {
      return new Promise((resolve, reject) => {
        Pasbus.getPasbusLines(user)
          .then(response => {
            const pasbuslines = response.data;
            console.log(JSON.stringify(pasbuslines));
            context.commit("setPasbusLines", pasbuslines);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setCridabusCities(context, user) {
      return new Promise((resolve, reject) => {
        Cridabus.getCridabusCities(user)
          .then(response => {
            const cities = response.data;
            console.log(JSON.stringify(cities));
            context.commit("setCridabusCities", cities);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setPasbusOrigens(context, payload) {
      return new Promise((resolve, reject) => {
        Pasbus.getPasbusStops(payload)
          .then(response => {
            // console.log(response.data.origen);
            const busstops = response.data;
            context.commit("setPasbusOrigens", busstops);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setPasbusDestins(context, payload) {
      return new Promise((resolve, reject) => {
        Pasbus.getPasbusStops(payload)
          .then(response => {
            // console.log(response.data.origen);
            const busstops = response.data;
            context.commit("setPasbusDestins", busstops);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setPasbusReasonTrip(context, user) {
      return new Promise((resolve, reject) => {
        Pasbus.pasbusReasonTrip(user)
          .then(response => {
            // console.log(response.data);
            const reasons = response.data;
            context.commit("setPasbusReasonTrip", reasons);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setPasbusRequest(context, pasbusRequest) {
      console.log("action dispached!");

      return new Promise((resolve, reject) => {
        Pasbus.pasbusReservation(pasbusRequest)
          .then(response => {
            // console.log(response.data);
            const reservation = response.data;
            context.commit("setPasbusRequest", reservation);

            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    actionLegalTextsModal(context, value) {
      context.commit("setLegalTextsModal", value);
    },
    setSubscriptionLines(context, userData) {
      return new Promise((resolve, reject) => {
        Subscriptions.getSubscriptionLines(userData)
          .then(response => {
            // console.log(response.data);
            const lines = response.data;
            context.commit("setLines", lines);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    getSubscriptions(context, user) {
      return new Promise((resolve, reject) => {
        Subscriptions.getSubscriptions(user)
          .then(response => {
            const subscriptions = response.data;
            const filteredSubscriptions = subscriptions.filter(
              e => e.id !== null
            );
            console.log("Filtered Subscriptions & Storing");
            context.commit("addSubscriptions", filteredSubscriptions);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    addSubscriptions(context, payload) {
      return new Promise((resolve, reject) => {
        Subscriptions.setSubscriptions(payload)
          .then(response => {
            if (response.data) {
              console.log("Subscribed OK:" + response.data);
            } else {
              // const resetSubscriptions = [];
              console.log("Not subscribed:" + response.data);
              // context.commit("addSubscriptions", resetSubscriptions);
            }
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    removeSubscription(context, payload) {
      return new Promise((resolve, reject) => {
        Subscriptions.removeSubscription(payload)
          .then(response => {
            let isDeleted = response.data;
            console.log("Deleted form server: " + isDeleted);
            context.commit("removeSubscription", payload.stop_id);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    getNotifications(context, user) {
      return new Promise((resolve, reject) => {
        Notifications.getNotifications(user)
          .then(response => {
            if (response.data) {
              // console.log("Notifications:" + response.data);
              const notifications = response.data;
              context.commit("setNotifications", notifications);
            } else {
              console.log("No hi ha notificacions:" + response.data);
            }
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    markNotificationReaded(context, payload) {
      return new Promise((resolve, reject) => {
        Notifications.markNotificationReaded(payload)
          .then(response => {
            let isRead = response.data;
            console.log("Marked as read: " + isRead);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    setUserPrefs(context, userPrefs) {
      context.commit("setUserPrefs", userPrefs);
    }
  },
  plugins: [createPersistedState()]
});
