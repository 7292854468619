<template>
  <v-container class="fill-height mt-md-4">
    <v-row class="text-center">
      <v-col cols="12" class="mb-2" align-self="start">
        <div v-if="!userLogged">
          <h2 class="header font-weight-bold my-2 white--text">
            BENVINGUT
          </h2>
          <p class="subheading font-weight-regular mb-5 white--text">
            Per gaudir de tota la funcionalitat et recomanem que et registri's i
            t'identifiquis com a usuari.
          </p>
          <v-spacer></v-spacer>
        </div>

        <div v-else>
          <h2 class="header font-weight-bold my-2 white--text">
            Hola {{ userName }}, benvingut/da!
          </h2>
          <h4 class="header-1 white--text">
            Pots veure la teva informació i configurar els avisos personalitzats
            de serveis al teu Espai Client.
          </h4>
          <v-btn color="info" class="mt-4" @click="navigateTo('/profile')">
            ESPAI CLIENT
          </v-btn>
          <v-spacer></v-spacer>
        </div>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/avisos')"
        >
          <v-icon>fa-info</v-icon>
        </v-btn>
        <h4 class="white--text">AVISOS</h4>
      </v-col>
      <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/pasbus')"
        >
          <v-icon>fa-bus</v-icon>
        </v-btn>
        <h4 class="white--text">PASBUS</h4>
      </v-col>
      <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/localitzabus')"
        >
          <v-icon>fa-map-marker-alt</v-icon>
        </v-btn>
        <h4 class="white--text">LOCALITZA BUS</h4>
      </v-col>
      <!-- <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/cridabus')"
        >
          <v-icon>fas fa-concierge-bell</v-icon>
        </v-btn>
        <h4 class="white--text">CRIDABUS</h4>
      </v-col> -->
      <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/horaris')"
        >
          <v-icon>fa-clock</v-icon>
        </v-btn>
        <h4 class="white--text">HORARIS</h4>
      </v-col>
      <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/serveis')"
        >
          <v-icon>fa-cubes</v-icon>
        </v-btn>
        <h4 class="white--text">SERVEIS</h4>
      </v-col>
      <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/empresa')"
        >
          <v-icon>fa-building</v-icon>
        </v-btn>
        <h4 class="white--text">EMPRESA</h4>
      </v-col>
      <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/canaltv')"
        >
          <v-icon>fa-tv</v-icon>
        </v-btn>
        <h4 class="white--text">CANAL TV</h4>
      </v-col>
      <v-col class="text-center">
        <v-btn
          class="ma-2"
          outlined
          large
          fab
          color="white"
          @click="navigateTo('/contacte')"
        >
          <v-icon>fa-headset</v-icon>
        </v-btn>
        <h4 class="white--text">CONTACTE</h4>
      </v-col>
    </v-row>

    <v-row v-if="!userLogged" class="text-center">
      <v-col>
        <p class="body-1 mt-2 white--text">
          Vols registrar-te o entrar com a usuari registrat?
        </p>
        <v-btn
          color="success"
          class="mb-4"
          @click="navigateTo('/login-register')"
        >
          SOM-HI!
        </v-btn>
      </v-col>
    </v-row>

    <v-row align-self="end" align="center" justify="center">
      <v-col cols="12" justify="center">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="80"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    // userIsLogged: false,
    userName: ""
  }),

  methods: {
    navigateTo(goTo) {
      this.$router.push({ path: goTo });
    },
    getUserName() {
      let logged = this.$store.getters.loggedIn;
      if (logged) {
        const user = this.$store.getters.getUserInfo;
        this.userName = user.name;
      }
      return;
    }
  },

  computed: {
    userLogged() {
      return this.$store.getters.loggedIn;
    }
  },

  created() {
    this.getUserName();
  },

  beforeMount() {
    const isLogged = this.$store.getters.loggedIn;
    if (isLogged) {
      const credentials = {
        token: this.$store.state.token,
        user_id: this.$store.state.user.userId
      };
      this.$store.dispatch("getSubscriptions", credentials);
      this.$store.dispatch("getNotifications", credentials);
      console.log("Getting Notifications on beforeMount Home");
    }
  },

  updated() {
    this.getUserName();
  }
};
</script>
