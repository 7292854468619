<template>
  <v-list dense nav active-class="active">
    <v-list-item
      v-for="(item, index) in menuLinks"
      :key="index"
      @click="navigateTo(item.to)"
      link
    >
      <v-list-item-icon>
        <v-icon small>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "MenuMobile",

  props: ["menuItems"],

  data: () => ({
    menuLinks: []
  }),

  mounted() {
    this.menuLinks = this.menuItems;
  },

  methods: {
    navigateTo(goTo) {
      this.$router.push(goTo).catch(() => {});
    }
  }
};
</script>
