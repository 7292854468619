<template>
  <v-container class="fill-height">
    <v-row class="text-center" align="center" justify="center">
      <v-col cols="12" md="8" class="mb-2 white--text" align-self="center">
        <h1 class="header font-weight-bold my-2 white--text">
          Woops!
        </h1>
        <h2 class="mb-4">La pàgina que buscaves no s'ha trobat.</h2>
        <v-spacer></v-spacer>
        <!-- <v-icon dark x-large> -->
        <i class="far fa-meh-rolling-eyes fa-8x my-5"></i>
        <!-- </v-icon> -->
        <h1 class="subheading font-weight-bold">
          404
        </h1>
        <h3>Not Found</h3>

        <v-spacer></v-spacer>

        <v-btn class="mt-5" to="/">Tornar a Inici</v-btn>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",

  data: () => ({})
};
</script>
