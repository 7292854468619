<template>
  <v-sheet color="rgba(0,0,0,0.5)" width="100%" height="100%">
    <v-container>
      <v-row v-if="errorMessages" justify="center">
        <v-alert dense type="error" class="mt-10">
          Oops! sembla que hi ha hagut algun problema de comunicació.<br />
          {{ errorMessages }}
        </v-alert>
      </v-row>

      <v-row align="start" justify="center">
        <v-card class="mt-md-10">
          <v-toolbar flat color="primary lighten-1" dark>
            <v-toolbar-title class="h6">Notificacions per a tu</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              class=""
              small
              outlined
              dark
              color="white"
              @click="closeNotificationsWidget()"
            >
              <v-icon dark small class="mr-1">
                fas fa-times
              </v-icon>
              TANCAR
            </v-btn>
          </v-toolbar>

          <v-responsive
            v-if="notifications.length >= 1"
            class="overflow-y-auto pa-2 mt-2"
            max-height="auto"
          >
            <v-alert
              v-for="(item, index) in notifications"
              :key="index"
              rounded
              outlined
              color="blue-grey darken-2"
              type="info"
              v-model="notifications[item.index]"
              dismissible
              dense
            >
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="8">
                    <h4 class="heading-6 pb-0">{{ item.title }}</h4>
                    <div v-html="item.message"></div>
                  </v-col>
                  <v-col cols="12" md="4 pr-md-6" class="text-right">
                    <h6 class="caption">VALIDESA</h6>
                    <h5>
                      <span class="caption">Des de:</span>
                      {{ parseISO(item.since) | dateTimeEUformat }}
                    </h5>
                    <h5>
                      <span class="caption">Fins a:</span>
                      {{ parseISO(item.until) | dateTimeEUformat }}
                    </h5>
                  </v-col>
                </v-row>
              </v-container>
              <template class="alert-actions-col" v-slot:close="{ toggle }">
                <v-btn
                  fab
                  color="red"
                  outlined
                  small
                  @click="dismissNotification(toggle, item.id)"
                  class="mt-n12"
                >
                  <v-icon small color="red">fas fa-trash</v-icon>
                </v-btn>
              </template>
            </v-alert>
          </v-responsive>
          <v-alert v-else type="info" elevation="2" dark>
            En aquests moments no hi ha notificacions per mostrar.
          </v-alert>
        </v-card>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { parseISO } from "date-fns";

export default {
  name: "notification-widget",

  data: () => ({
    errorMessages: null,
    isLoading: true,
    parseISO: parseISO,
    notifications: []
  }),

  props: ["user"],

  created() {
    this.notifications = this.$store.getters.getNotifications;
  },

  mounted() {},

  methods: {
    async markAsRead(itemId) {
      const payload = {
        token: this.$store.state.token,
        user_id: this.user,
        notification_id: itemId
      };
      try {
        const readedNotification = await this.$store.dispatch(
          "markNotificationReaded",
          payload
        );
        if (readedNotification) {
          console.log(
            "La notificació amb id:" +
              payload.notification_id +
              "s'ha marcat com a llegida."
          );
          this.notifications = this.$store.getters.getNotifications;
          return true;
        } else {
          console.log("No s'ha pogut marcar con a llegida en el servei");
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    dismissNotification(dismissFn, item) {
      const read = this.markAsRead(item);
      if (read !== true) {
        console.log("No es pot marcar com llegida");
        dismissFn();
      } else {
        dismissFn();
      }
    },
    closeNotificationsWidget() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.alert-actions-col {
  vertical-align: top;
}
</style>
