<template>
  <v-app ref="appContainer">
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mobile"
      v-model="drawer"
      app
      color="primary darken-1"
      dark
    >
      <MenuMobile :menuItems="menuItems" />
      <v-divider></v-divider>
      <v-container>
        <v-row dense class="pt-4">
          <h4 class="white--text pl-2">Segueix-nos!</h4>
          <v-col cols="12">
            <v-btn
              @click="openURL(smUrls.youtube)"
              text
              icon
              color="white"
              class="mx-2"
            >
              <v-icon>fab fa-youtube</v-icon>
            </v-btn>
            <v-btn
              @click="openURL(smUrls.instagram)"
              text
              icon
              color="white"
              class="mx-2"
            >
              <v-icon>fab fa-instagram</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <!-- Mobile AppBar -->
    <v-app-bar app color="red accent-4" dark v-if="$vuetify.breakpoint.mobile">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-img
        alt="PNDS Logo"
        class="shrink mb-2 mx-auto"
        contain
        src="@/assets/img/logo-pds-mobilitat.png"
        transition="scale-transition"
        height="80"
      />

      <v-spacer></v-spacer>

      <div v-show="numNotifications >= 1 && isLoggedIn && notificationsWidget">
        <v-badge
          :content="numNotifications"
          :value="notifications"
          color="info"
          class="mr-3"
          overlap
          dark
          right
          small
        >
          <v-btn icon small text @click="showNotificationsWidget()">
            <v-icon>
              fas fa-bell
            </v-icon>
          </v-btn>
        </v-badge>
      </div>

      <div class="text-right ml-2">
        <v-menu v-if="isLoggedIn" right offset-y dark bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark icon v-bind="attrs" v-on="on">
              <v-icon>fa fa-user-circle fa-fw</v-icon>
            </v-btn>
          </template>

          <v-list dense color="primary">
            <v-list-item-group>
              <v-list-item @click="goProfile">
                <v-list-item-icon class="mr-1">
                  <v-icon small>far fa-address-card fa-fw</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>ESPAI CLIENT</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-icon class="mr-1">
                  <v-icon small>fas fa-sign-out-alt fa-fw</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>SORTIR</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn v-else text small @click="login">
          <v-icon>fa fa-user-circle fa-fw</v-icon>
        </v-btn>
      </div>

      <v-btn text small v-if="showReloadBtn" @click="reload">
        <v-icon>fas fa-sync-alt</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Desktop AppBar -->
    <v-app-bar app min-height="90" color="red accent-4" dark v-else>
      <v-row justify="space-between" dense>
        <div class="d-flex align-end">
          <v-img
            src="@/assets/img/logo-autocars-del-penedes-petit.png"
            class="shrink mt-5"
            contain
            max-height="80"
          />
          <v-img
            alt="PNDES Logo"
            class="shrink mb-2"
            contain
            src="@/assets/img/logo-pds-mobilitat.png"
            transition="scale-transition"
            max-width="155"
          />
        </div>

        <div class="d-flex align-start">
          <v-container class="pa-0 ma-0">
            <v-row dense justify="end">
              <v-col cols="2" class="pb-0 pt-4 mb-0 text-right">
                <h5 class="white--text mt-4">Segueix-nos!</h5>
              </v-col>
              <v-col cols="2" class="pb-0 pt-4 mb-0">
                <v-btn @click="openURL(smUrls.youtube)" text icon color="white">
                  <v-icon>fab fa-youtube</v-icon>
                </v-btn>

                <v-btn
                  @click="openURL(smUrls.instagram)"
                  text
                  icon
                  color="white"
                >
                  <v-icon>fab fa-instagram</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col>
                <MenuDesktop :menuItems="menuItems" />
              </v-col>
            </v-row>
          </v-container>
        </div>

        <div class="d-flex align-center pt-13">
          <div
            v-show="numNotifications >= 1 && isLoggedIn && notificationsWidget"
          >
            <v-badge
              :content="numNotifications"
              :value="notifications"
              color="info"
              class="mr-3"
              overlap
              dark
              right
              small
            >
              <v-btn icon small text @click="showNotificationsWidget()">
                <v-icon>
                  fas fa-bell
                </v-icon>
              </v-btn>
            </v-badge>
          </div>
          <v-menu v-if="isLoggedIn" right offset-y dark bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small dark icon v-bind="attrs" v-on="on" class="mr-1">
                <v-icon>fa fa-user-circle</v-icon>
              </v-btn>
            </template>

            <v-list dense color="primary">
              <v-list-item-group>
                <v-list-item @click="goProfile">
                  <v-list-item-icon class="mr-1">
                    <v-icon small>far fa-address-card fa-fw</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>ESPAI CLIENT</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-icon class="mr-1">
                    <v-icon small>fas fa-sign-out-alt fa-fw</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>SORTIR</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <v-btn v-else text small @click="login">
            <v-icon>fa fa-user-circle fa-fw</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-app-bar>

    <v-main class="main pt-md-16">
      <!-- Banner In Development Advice -->
      <v-system-bar
        height="auto"
        class="text-center mt-md-7"
        color="light-blue darken-4"
        v-if="showBannerInDevelopment"
        dark
        window
      >
        <div class="mx-auto">
          Algunes de les funcionalitats estàn en fase de desenvolupament i és
          possible que encara no funcionin. Agraïm la seva comprensió.
        </div>
        <v-btn
          small
          class="ml-md-3"
          color="white"
          @click="hideBannerInDevelopment()"
          text
        >
          <v-icon>far fa-times-circle</v-icon>
        </v-btn>
      </v-system-bar>
      <!-- Promote app install -->
      <v-banner
        v-if="checkIosInstallPopup"
        color="info"
        two-line
        dark
        tile
        elevation="1"
        transition="slide-y-transition"
      >
        <v-avatar slot="icon" size="40">
          <v-icon color="white" large>
            fa-info-circle
          </v-icon>
        </v-avatar>
        <strong>Afegeix aquest lloc com una App més al teu dispositiu.</strong>
        <br />
        Utilitza la funció d'accions del teu iPhone
        <v-icon icon="export-variant" color="white">
          mdi-export-variant
        </v-icon>
        <!-- <v-icon small icon="ios-share">mdi-ios-share</v-icon>  -->
        i selecciona 'Afegir a pantalla d'inici'.
        <template v-slot:actions="{ dismiss }">
          <v-btn
            small
            color="white"
            outlined
            @click="dismiss && setIosInstallPopup()"
          >
            No mostrar més
          </v-btn>
          <v-btn small color="white" outlined @click="dismiss">
            Tancar
          </v-btn>
        </template>
      </v-banner>
      <!-- Notifications Alert -->
      <v-snackbar
        app
        bottom
        center
        width="auto"
        color="blue"
        timeout="-1"
        dark
        v-model="notificationsAlert"
        class="mt-6"
      >
        <v-icon small icon="far fa-bell" color="white">
          far fa-bell
        </v-icon>

        Tens {{ numNotifications }} notificacions sobre les teves subscripcions.

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            small
            outlined
            v-bind="attrs"
            @click="showNotificationsWidget()"
          >
            Veure
          </v-btn>
          <v-btn
            color="white"
            text
            small
            v-bind="attrs"
            @click="notificationsAlert = false"
          >
            <v-icon dark small>fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Notifications Widget -->
      <v-dialog
        v-if="isLoggedIn && userInfo !== null"
        transition="dialog-top-transition"
        :value="showWidgetDialog"
        persistent
        fullscreen
        scrollable
        hide-overlay
      >
        <NotificationsWidget
          :user="userInfo.userId"
          v-on:close="hideNotificationsWidget()"
        />
      </v-dialog>

      <router-view></router-view>

      <v-snackbar
        bottom
        right
        :value="updateExists"
        :timeout="-1"
        color="primary"
        app
        dark
      >
        Actualització disponible
        <v-btn text @click="refreshApp">
          Actualitzar
        </v-btn>
      </v-snackbar>
    </v-main>

    <v-dialog
      v-model="showLegalTextsSheet"
      transition="dialog-bottom-transition"
      width="auto"
      max-width="900px"
      persistent
    >
      <v-card>
        <v-row class="pa-2">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ma-1"
            text
            @click.stop="closeLegalTexts()"
          >
            TANCAR
          </v-btn>
        </v-row>
        <v-card-text>
          <LegalTexts :tab="tab" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Footer -->
    <v-footer :v-app="$vuetify.breakpoint.mdAndUp" dark paddless>
      <v-row dense justify="center">
        <v-col cols="12" md="4" class="body-2 text-center pt-2">
          Autocars del Penedès, S.A. |
          <small>
            {{ new Date().getFullYear() }} Tots els drets reservats.
          </small>
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <v-item-group>
            <!-- <v-item>
              <v-btn @click.stop="openLegalTexts(1)" text small dark
                >Avís legal</v-btn
              >
            </v-item> -->
            <v-item>
              <v-btn @click.stop="openLegalTexts(0)" text small dark
                >Política de privacitat</v-btn
              >
            </v-item>
            <!-- <v-item>
              <v-btn @click.stop="openLegalTexts(2)" text small dark
                >Condicions d'ús</v-btn
              >
            </v-item> -->
          </v-item-group>
        </v-col>
        <v-col cols="12" md="4">
          <div class="text-center grey--text">
            <small>{{ appVersion }}</small>
            <a
              href="https://visibletic.com"
              target="_blank"
              class="ml-5 grey--text text-decoration-none"
            >
              <small>Developed by VISIBLE IT</small>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import MenuMobile from "@/components/MenuMobile";
import MenuDesktop from "@/components/MenuDesktop";
import LegalTexts from "@/components/LegalTexts";

import update from "./mixins/update";
import NotificationsWidget from "@/components/NotificationsWidget";

export default {
  name: "App",

  components: {
    MenuMobile,
    MenuDesktop,
    LegalTexts,
    NotificationsWidget
  },

  data: () => ({
    appVersion: process.env.VUE_APP_VERSION,
    drawer: false,
    menuItems: [
      {
        text: "INICI",
        icon: "fa-home fa-fw",
        to: "/"
      },
      {
        text: "AVISOS",
        icon: "fa-info fa-fw",
        to: "/avisos"
      },
      {
        text: "PASBUS",
        icon: "fa-bus fa-fw",
        to: "/pasbus"
      },
      {
        text: "LOCALITZA BUS",
        icon: "fa-map-marker-alt fa-fw",
        to: "/localitzabus"
      },
      // {
      //   text: "CRIDABUS",
      //   icon: "fas fa-concierge-bell fa-fw",
      //   to: "/cridabus"
      // },
      {
        text: "HORARIS",
        icon: "fa-clock fa-fw",
        to: "/horaris"
      },
      {
        text: "SERVEIS",
        icon: "fa-cubes fa-fw",
        to: "/serveis"
      },
      {
        text: "EMPRESA",
        icon: "fa-building fa-fw",
        to: "/empresa"
      },
      {
        text: "CANAL TV",
        icon: "fa-tv fa-fw",
        to: "/canaltv"
      },
      {
        text: "CONTACTE",
        icon: "fa-headset fa-fw",
        to: "/contacte"
      }
    ],
    showIosInstallPopup: true,
    tab: 0,
    // token: null,
    showError: false,
    alertMsg: "Oops! S'ha produït un error de comunicació amb el servidor.",
    notificationsAlert: false,
    showNotifications: false,
    showWidgetDialog: false,
    smUrls: {
      youtube: "https://www.youtube.com/channel/UCRvsl5sv-GTpcYtchptHOqA",
      instagram: "https://www.instagram.com/autocars_del_penedes_pnds/"
    }
  }),

  mixins: [update],

  computed: {
    isLoggedIn: function() {
      return this.$store.getters.loggedIn || false;
    },
    userInfo: function() {
      return this.$store.getters.getUserInfo || null;
    },
    checkIosInstallPopup: function() {
      let showPopup = this.$store.getters.getIosInstallPopup;
      if (showPopup === null && this.showIosInstallPopup === true) {
        return true;
      } else {
        return false;
      }
    },
    showLegalTextsSheet: function() {
      return this.$store.getters.getLegalTextsModal;
    },
    showBannerInDevelopment: function() {
      return this.$store.getters.getBannerInDevelopment;
    },
    subscriptions: function() {
      return this.$store.getters.getSubscriptions;
    },
    notifications: function() {
      return this.$store.getters.getNotifications;
    },
    numNotifications: function() {
      let valNum = this.notifications.length || 0;
      return valNum;
    },
    notificationsWidget: function() {
      return this.$store.getters.getUserPrefs.notificationsWidget;
    },
    notificationsWidgetStatus: function() {
      let visible =
        this.$store.getters.getUserPrefs.notificationsWidget &&
        this.numNotifications >= 1 &&
        this.isLoggedIn;
      return visible;
    },
    showNotificationsAlert: function() {
      if (
        this.numNotifications >= 1 &&
        this.notificationsAlert &&
        this.isLoggedIn
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  created() {
    if (this.$store.getters.isLoggedIn) {
      this.userInfo = this.$store.getters.getUserInfo;
      // this.token = this.$store.state.token;
      this.getSubscriptions();
    }
  },

  mounted() {
    this.iosInstallPopup();
    if (this.showNotificationsAlert) {
      this.notificationsAlert = true;
    }
  },

  // updated() {
  //   if (this.$store.getters.isLoggedIn) {
  //     this.userInfo = this.$store.getters.getUserInfo;
  //     this.token = this.$store.state.token;
  //     if (this.token === null) {
  //       this.logout();
  //     }
  //   }
  // },

  methods: {
    login() {
      this.$router.push({ path: "/login-register" }).catch(() => {});
    },
    logout() {
      this.$store.dispatch("destroyToken");
      this.$router.push("/login-register").catch(() => {});
    },
    // Detects if device is in standalone mode
    showReloadBtn() {
      const isInStandaloneMode = () =>
        "standalone" in window.navigator && window.navigator.standalone;
      return isInStandaloneMode;
    },
    // Detects if device is in iOS in standalone mode
    iosInstallPopup() {
      // Detects if device is on iOS
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        // console.log("Is iOS");
        return /iphone|ipad|ipod/.test(userAgent);
      };
      // Detects if device is in standalone mode
      const isInStandaloneMode = () =>
        "standalone" in window.navigator && window.navigator.standalone;

      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        this.showIosInstallPopup = true;
      } else {
        this.showIosInstallPopup = false;
      }
    },
    setIosInstallPopup() {
      this.$store.dispatch("setIosInstallPopup");
    },
    reload() {
      return location.reload(true);
    },
    hideBannerInDevelopment() {
      this.$store.commit("hideBannerInDevelopment");
    },
    openLegalTexts(tab) {
      this.tab = tab;
      this.$store.dispatch("actionLegalTextsModal", true);
    },
    closeLegalTexts() {
      this.$store.dispatch("actionLegalTextsModal", false);
      this.tab = 0;
    },
    openURL(url) {
      window.open(url, "_blank");
    },
    goProfile() {
      this.$router.push("/profile").catch(() => {});
    },
    errorAlert() {
      this.showError = true;
      var v = this;
      setTimeout(function() {
        v.showError = false;
      }, 4000);
    },
    async getSubscriptions() {
      const user = {
        token: this.$store.state.token,
        user_id: this.userInfo.userId
      };
      await this.$store
        .dispatch("getSubscriptions", user)
        .then(response => {
          console.log("S'han obtingut les subscripcions");
          if (response.data.length >= 1) {
            this.getNotifications();
          }
        })
        .catch(error => {
          // this.alertMsg = "Oops! S'ha produït un error inesperat.";
          // this.errorAlert();
          console.log(error);
        });
    },
    async getNotifications() {
      const userData = {
        token: this.$store.state.token,
        user_id: this.userInfo.userId
      };
      try {
        const notifications = await this.$store.dispatch(
          "getNotifications",
          userData
        );
        if (notifications.length >= 1) {
          this.notificationsAlert = true;
        } else {
          this.notificationsAlert = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    showNotificationsWidget() {
      this.notificationsAlert = false;
      this.showNotifications = true;
      this.showWidgetDialog = true;
    },
    hideNotificationsWidget() {
      this.notificationsAlert = true;
      this.showNotifications = false;
      this.showWidgetDialog = false;
    }
  }
};
</script>

<style lang="scss">
.main {
  background: rgba(37, 36, 36, 0.685);
  background-image: url(assets/img/road-bg-o.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center;
}
</style>
