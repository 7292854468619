import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#b70202",
        secondary: "#263238",
        accent: "#ff5252",
        error: "#b71c1c"
      }
    }
  },
  icons: {
    iconfont: "fa"
  }
});
