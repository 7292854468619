// api/auth.js

import axios from "axios";
const API_URL =
  process.env.VUE_APP_API_URL + "/subscription" || "apiv1/subscription";
// const API_URL = "api/subscription";

export default {
  getSubscriptionLines(user) {
    return axios.get(API_URL + "/lines", {
      params: {
        token: user.token,
        user_id: user.user_id
      }
    });
  },

  getSubscriptionStops(payload) {
    return axios.get(API_URL + "/line/stops", {
      params: {
        token: payload.token,
        user_id: payload.user_id,
        line_id: payload.line_id
      }
    });
  },

  setSubscriptions(payload) {
    return axios.post(API_URL + "/checkin", {
      token: payload.token,
      user_id: payload.user_id,
      stops: payload.stops
    });
  },

  removeSubscription(payload) {
    return axios.post(API_URL + "/uncheckin", {
      token: payload.token,
      user_id: payload.user_id,
      stop_id: payload.stop_id
    });
  },

  getSubscriptions(user) {
    return axios.get(API_URL + "/subscriptions", {
      params: {
        token: user.token,
        user_id: user.user_id
      }
    });
  }
};
