// api/auth.js

import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL + "/cridabus" || "apiv1/cridabus";
// const API_URL = "api/cridabus";

export default {
  getCridabusCities(user) {
    return axios.get(API_URL + "/cities", {
      params: {
        token: user.token,
        user_id: user.user_id
      }
    });
  },
  getCridabusStopsLocation(payload) {
    return axios.get(API_URL + "/stops/location", {
      params: {
        token: payload.token,
        user_id: payload.user_id,
        latitude: payload.lat,
        longitude: payload.lng,
        radio: payload.radio,
        city: payload.city
      }
    });
  },
  getCridabusStops(payload) {
    return axios.get(API_URL + "/stops", {
      params: {
        token: payload.token,
        user_id: payload.user_id,
        city: payload.city
      }
    });
  },
  cridabusReservation(payload) {
    return axios.post(API_URL + "/reservation", {
      token: payload.token,
      user_id: payload.user_id,
      source_id: payload.source_id,
      destination_id: payload.destination_id
    });
  }
};
