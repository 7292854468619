<template>
  <v-btn-toggle dense text color="white" group>
    <v-btn
      text
      v-for="(item, index) in menuLinks"
      :key="index"
      @click="navigateTo(item.to)"
    >
      {{ item.text }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "MenuDesktop",

  props: ["menuItems"],

  data: () => ({
    menuLinks: []
  }),

  mounted() {
    this.menuLinks = this.menuItems;
  },

  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/login").catch(() => {});
    },

    navigateTo(goTo) {
      this.$router.push(goTo).catch(() => {});
    }
  }
};
</script>
