import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import ResetPassword from "../views/ResetPassword.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/login-register",
    name: "login-register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "login-register" */ "../views/LoginRegister.vue"
      )
  },
  {
    path: "/avisos",
    name: "avisos",
    component: () =>
      import(/* webpackChunkName: "avisos" */ "../views/Avisos.vue")
  },
  {
    path: "/horaris",
    name: "horaris",
    component: () =>
      import(/* webpackChunkName: "horaris" */ "../views/Horaris.vue")
  },
  {
    path: "/serveis",
    name: "serveis",
    component: () =>
      import(/* webpackChunkName: "serveis" */ "../views/Serveis.vue")
  },
  {
    path: "/empresa",
    name: "empresa",
    component: () =>
      import(/* webpackChunkName: "empresa" */ "../views/Empresa.vue")
  },
  {
    path: "/contacte",
    name: "contacte",
    component: () =>
      import(/* webpackChunkName: "contacte" */ "../views/Contactar.vue")
  },
  {
    path: "/canaltv",
    name: "canaltv",
    component: () =>
      import(/* webpackChunkName: "canaltv" */ "../views/CanalTv.vue")
  },
  {
    path: "/pasbus",
    name: "pasbus",
    component: () =>
      import(/* webpackChunkName: "pasbus" */ "../views/Pasbus.vue")
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: "/localitzabus",
    name: "localitzabus",
    component: () =>
      import(
        /* webpackChunkName: "localitzabus" */ "../views/LocalitzaBus.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: "/cridabus",
  //   name: "cridabus",
  //   component: () =>
  //     import(/* webpackChunkName: "cridabus" */ "../views/CridaBus.vue"),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/UserProfile.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ "../views/UserSubscriptions.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPassword
  },
  {
    path: "/404",
    name: "404",
    component: NotFound
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_ROUTER_BASE,
  routes
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
});

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !store.state.token) {
    next("/login-register");
  } else {
    next();
  }
});

export default router;
